<template>
  <span>
    <ColorPreview :color="term.color" />

    {{ truncatedTermName }} {{ occurances ? `(${occurances})` : '' }}
  </span>
</template>

<script>
import ColorPreview from '@/components/utils/ColorPreview.vue';

export default {
  name: 'CytomineTerm',
  components: {
    ColorPreview,
  },
  props: {
    term: {
      type: Object,
      required: true,
    },
    occurances: {
      type: Number,
      required: false,
    },
  },
  computed: {
    truncatedTermName() {
      return this.term.name.length > 60
        ? `${this.term.name.substring(0, 60)}...`
        : this.term.name;
    },
  },
};
</script>
