<template>
  <span>
    <span
      v-if="color"
      :style="{ background: color }"
      class="color-preview relative inline-block mr-1 radius-4"
    />
    <span v-else>
      <img
        :src="require('@/assets/static/no-color.png')"
        class="color-preview relative inline-block mr-1 radius-4 img"
      />
    </span>
  </span>
</template>

<script>
export default {
  name: 'ColorPreview',
  props: {
    color: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.color-preview {
  width: 1em;
  height: 1em;
  box-shadow: 0 0 1px #777;
  top: 0.2em;

  &.img {
    top: 0em;
  }
}
</style>
